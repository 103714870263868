import React from 'react';
import { Link } from 'react-router-dom';

const DefaultButton = ({
  label,
  link,
  to,
  fullWidth = false,
  onClick,
  bgColor = 'bg-primary',
  disabledBgColor = 'disabled:bg-primary-200',
  bgColorHover = 'hover:bg-primary-200',
  textColor = 'text-white',
  xs = false,
  sm = false,
  ...props
}) => {
  let sizingClasses = 'py-2 px-4 text-sm';
  if (xs) {
    sizingClasses = 'py-1 px-3 text-xs';
  }
  if (sm) {
    sizingClasses = 'py-2 px-3 text-xs';
  }

  const className = `${
    fullWidth && 'w-full'
  } group relative flex justify-center ${disabledBgColor} border border-transparent font-medium rounded-md ${textColor} ${sizingClasses} ${bgColor} ${bgColorHover} focus:outline-none`;

  if (link) {
    return (
      <Link to={to} className={className} {...props}>
        {label}
      </Link>
    );
  }

  return (
    <button onClick={onClick} className={className} {...props}>
      {label}
    </button>
  );
};

export default DefaultButton;
