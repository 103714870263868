import React from 'react';
import Navbar from '@hoc/partials/Navbar';

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col h-screen">
      <Navbar />
      <div className="mx-10 md:mx-20 lg:mx-40 flex-1 mt-10">{children}</div>
    </div>
  );
};

export default Layout;
