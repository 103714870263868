import React, { useEffect, useState } from 'react';
import Layout from '@hoc/layouts/Layout';
import Input from '@core/inputs/Input';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import Select from '@core/Select';
import { animalTypes } from '@constants/index';
import SubmitButton from '@core/buttons/atoms/SubmitButton';
import API from '@utils/plugins/API';
import LoadingKeeper from '@components/Keeper/LoadingKeeper';
import { showError, showSuccess } from '@utils/helpers';
import BookingAvailableAnimation from '@components/Checkout/BookingAvailableAnimation';
import BookingUnavailableAnimation from '@components/Checkout/BookingUnavailableAnimation';
import { useHistory } from 'react-router-dom';

const CheckoutPage = () => {
  const {
    startDate: reduxStartDate,
    endDate: reduxEndDate,
    typeOfPets: reduxTypeOfPets,
    numberOfPets: reduxNumberOfPets = 1,
    startTime: reduxStartTime,
    endTime: reduxEndTime,
    date: reduxDate,
    keeperId,
    isWalker
  } = useSelector((state) => state.keeperReducer);
  const [startDate, setStartDate] = useState(reduxStartDate);
  const [endDate, setEndDate] = useState(reduxEndDate);
  const [numberOfPets, setNumberOfPets] = useState(reduxNumberOfPets);
  const [typeOfPets, setTypeOfPets] = useState(reduxTypeOfPets);
  const [isLoading, setIsLoading] = useState(true);
  const [isAvailable, setIsAvailable] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const [keeperName, setKeeperName] = useState('');
  const [message, setMessage] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);
  const [date, setDate] = useState(reduxDate);
  const [startTime, setStartTime] = useState(reduxStartTime);
  const [endTime, setEndTime] = useState(reduxEndTime);

  useEffect(() => {
    if (!keeperId) {
      history.push('/dashboard');
    }
    setIsLoading(true);
    const payload = {
      ...(isWalker ? { date, startTime, endTime } : { startDate, endDate }),
      numberOfPets,
      typeOfPets,
      keeperId
    };
    API.get('/isAvailable', { params: payload })
      .then((res) => {
        const { keeperName, totalPrice } = res.data;
        setKeeperName(keeperName);
        setIsAvailable(true);
        setMessage('');
        setTotalPrice(totalPrice);
      })
      .catch((err) => {
        setMessage(err.response.data);
        setIsAvailable(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [startDate, endDate, numberOfPets, typeOfPets, startTime, date, endTime]);

  const bookKeeper = () => {
    setIsSubmitting(true);
    const payload = {
      ...(isWalker ? { date, startTime, endTime } : { startDate, endDate }),
      numberOfPets,
      typeOfPets,
      keeperId,
      totalPrice,
      isWalker
    };
    API.post('/book', payload)
      .then((res) => {
        showSuccess(res.data.message);
        history.push('/dashboard');
      })
      .catch((err) => {
        showError(err.response.data.error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleStartDateChange = (value) => {
    if (endDate && moment(endDate).diff(moment(value), 'day') < 1) {
      setEndDate('');
    }
    setStartDate(value);
  };

  return (
    <Layout>
      <div className="grid md:grid-cols-2 gap-10">
        <div>
          <h1 className="text-lg md:text-xl lg:text-2xl font-medium">
            Order Details {isAvailable && `for ${keeperName}`}
          </h1>
          <div className="grid grid-cols-2 gap-4 my-2.5">
            {isWalker ? (
              <>
                <Input
                  type="date"
                  label="Date"
                  placeholder="Date"
                  value={date}
                  min={moment().format('YYYY-MM-DD')}
                  onChange={setDate}
                  containerDiv="col-span-2"
                />
                <Input
                  label="Start Time"
                  type="time"
                  placeholder="Start Time"
                  onChange={setStartTime}
                  value={startTime}
                  min="08:00"
                  step={1800}
                  max="20:00"
                />
                <Input
                  label="End Time"
                  type="time"
                  placeholder="End Time"
                  onChange={setEndTime}
                  step={1800}
                  value={endTime}
                  min={moment(startTime, 'HH:mm').add(30, 'minutes').format('HH:mm')}
                  max="20:00"
                />
              </>
            ) : (
              <>
                <Input
                  type="date"
                  label="Start Date"
                  placeholder="Start Date"
                  value={startDate}
                  min={moment().format('YYYY-MM-DD')}
                  onChange={handleStartDateChange}
                />
                <Input
                  type="date"
                  label="End Date"
                  placeholder="End Date"
                  value={endDate}
                  min={moment(startDate).add(1, 'day').format('YYYY-MM-DD')}
                  onChange={setEndDate}
                />
              </>
            )}

            <Select
              options={animalTypes}
              placeholder="Type Of Pets"
              label="Type of pets"
              value={typeOfPets}
              onChange={setTypeOfPets}
            />
            <Input
              label="Number of pets"
              type="number"
              min={1}
              placeholder="Number Of Pets"
              value={numberOfPets}
              onChange={setNumberOfPets}
            />
          </div>
          <SubmitButton
            isLoading={isSubmitting}
            label="Book"
            disabled={!isAvailable}
            onClick={bookKeeper}
          />
        </div>
        <div>
          {isLoading ? (
            <LoadingKeeper />
          ) : isAvailable ? (
            <BookingAvailableAnimation />
          ) : (
            <BookingUnavailableAnimation message={message} />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default CheckoutPage;
