import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { socket } from '../../socket';
import MyMessage from '@components/Chat/partials/MyMessage';
import ReceiverMessage from '@components/Chat/partials/ReceiverMessage';
import Lottie from '@core/Lottie';

const Chat = ({ roomId, correspondentName }) => {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const userId = useSelector((state) => _.get(state, 'meReducer.userid', ''));
  const chatRef = useRef();

  useEffect(() => {
    if (roomId) {
      socket.emit('join room', roomId, userId);
      socket.on('previous messages', (data) => {
        setMessages(data);
      });
      socket.on('chat message', (data) => {
        setMessages((prevState) => [...prevState, data]);
      });
    }

    return () => {
      socket.off('chat message');
      socket.off('previous messages');
    };
  }, [roomId]);

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (message.trim()) {
      socket.emit('chat message', roomId, userId, message);
    }
    setMessage('');
  };

  useEffect(() => {
    chatRef?.current?.scrollIntoView();
  }, [messages]);

  if (!roomId) {
    return (
      <div className="flex items-center w-full h-screen justify-center">
        <div className="w-40 md:w-50 lg:w-72">
          <Lottie
            options={{ autoPlay: true, loop: true }}
            animationData={require('@assets/animations/chat.json')}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="relative">
      <div className="bg-white p-5 h-screen overflow-y-scroll overflow-x-scroll pb-15">
        {messages.map((message, index, arr) => {
          const isPreviousMessageSentByUser = _.get(arr, `[${index - 1}].sender_id`, '') === userId;
          return message.sender_id === userId ? (
            <MyMessage
              isPreviousMessageSentByUser={isPreviousMessageSentByUser}
              content={message.message_text}
            />
          ) : (
            <ReceiverMessage
              isPreviousMessageSentByUser={!isPreviousMessageSentByUser}
              user={correspondentName}
              text={message.message_text}
            />
          );
        })}
        <div ref={chatRef} />
      </div>
      <form
        className="flex absolute p-2.5 bg-red-200 bottom-0 items-center w-full"
        onSubmit={handleSendMessage}>
        <input
          type="text"
          placeholder="Write message"
          className="p-1.5 lg:p-2.5 text-sm lg:text-base outline-none message w-3/4"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <button
          className="flex-1 text-white text-sm bg-gray-400 px-2.5 py-1.5 lg:py-3 rounded-tr-xl rounded-br-xl"
          type="submit">
          Send
        </button>
      </form>
    </div>
  );
};

export default Chat;
