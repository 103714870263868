import React from 'react';

const Sidebar = ({ sortColumn, sortDirection, setSortColumn, setSortDirection }) => {
  return (
    <div className="w-48 lg:w-56 border pl-6 py-3">
      <div className="text-lg ml-6 mt-1.5 font-medium">Sort</div>
      <div className="flex items-center space-x-1">
        <input
          type="checkbox"
          className="w-3 h-3"
          checked={sortColumn === 'priceperpetperday' && sortDirection === 'DESC'}
          onChange={() => {
            setSortColumn('priceperpetperday');
            setSortDirection('DESC');
          }}
        />
        <div>Price Desc</div>
      </div>
      <div className="flex items-center space-x-1">
        <input
          type="checkbox"
          className="w-3 h-3"
          checked={sortColumn === 'priceperpetperday' && sortDirection === 'ASC'}
          onChange={() => {
            setSortColumn('priceperpetperday');
            setSortDirection('ASC');
          }}
        />
        <div>Price ASC</div>
      </div>
      <div className="flex items-center space-x-1">
        <input
          type="checkbox"
          className="w-3 h-3"
          checked={sortColumn === 'average_score' && sortDirection === 'DESC'}
          onChange={() => {
            setSortColumn('average_score');
            setSortDirection('DESC');
          }}
        />
        <div>Ratings</div>
      </div>
    </div>
  );
};

export default Sidebar;
