import React from 'react';
import RadioInput from '@core/inputs/RadioInput';

const SearchTypeInput = ({ value, onChange, error }) => {
  return (
    <div>
      <div className="w-full px-2 font-medium text-gray-900 bg-white border border-gray-200 rounded-lg grid grid-cols-2">
        <RadioInput
          id="keeper-type-input"
          onChange={() => onChange('keeper')}
          checked={value === 'keeper'}
          label="Keeper"
        />
        <RadioInput
          id="walker-type-input"
          onChange={() => onChange('walker')}
          checked={value === 'walker'}
          label="Walker"
        />
      </div>
      {error && <div className={`text-xs text-red-400 pt-2`}>{error}</div>}
    </div>
  );
};

export default SearchTypeInput;
