import React, { useState } from 'react';
import BlankModal from '@core/modals/BlankModal';
import Input from '@core/inputs/Input';
import SubmitButton from '@core/buttons/atoms/SubmitButton';
import API from '@utils/plugins/API';
import { showError, showSuccess } from '@utils/helpers';
import { useHistory } from 'react-router-dom';

const EmailVerificationModal = ({ setOpenModal, isOpen, email }) => {
  const [confirmationCode, setConfirmationCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const verifyEmail = () => {
    setIsLoading(true);
    API.post('/confirm', { email, confirmationCode })
      .then((res) => {
        showSuccess(res.data.message);
        history.push('/login');
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <BlankModal title="Verify Email" isOpen={isOpen} setOpenModal={setOpenModal}>
      <div className="space-y-3">
        <p>Please check your email for a verification code</p>
        <Input
          placeholder="Confirmation Code"
          value={confirmationCode}
          type="number"
          onChange={setConfirmationCode}
        />
        <SubmitButton isLoading={isLoading} label="Confirm" onClick={verifyEmail} />
      </div>
    </BlankModal>
  );
};

export default EmailVerificationModal;
