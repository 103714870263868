import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

const Lottie = ({ options, animationData, width, height }) => {
  const element = useRef(null);

  useEffect(() => {
    if (element.current) {
      lottie.loadAnimation({
        loop: false,
        autoplay: true,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        },
        animationData,
        container: element.current,
        ...options
      });
    }
  }, [animationData]);

  return <div style={{ width, height }} ref={element} />;
};

export default Lottie;
