import React, { useEffect, useState } from 'react';
import Layout from '@hoc/layouts/Layout';
import API from '@utils/plugins/API';
import moment from 'moment';

const formatDate = (date, format = 'DD.MM.YYYY') => {
  return moment(date).format(format);
};

const MyBookingsPage = () => {
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    API.get('/user/bookings').then((res) => {
      setBookings(res.data);
    });
  }, []);

  const TABLE_HEAD = ['Name', 'Booking Type', 'Date', 'Status'];

  return (
    <Layout>
      <div className="text-lg font-semibold">My Bookings</div>
      <table className="w-full table-auto text-left overflow-x-scroll">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 font-medium text-xs lg:text-base bg-blue-gray-50 p-4">
                {head}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {bookings.map((booking, index) => {
            const isLast = index === bookings.length - 1;
            const className = `text-xs lg:text-base ${
              isLast ? 'p-4' : 'p-4 border-b border-blue-gray-50'
            }`;

            return (
              <tr key={index}>
                <td className={className}>{booking.keepername}</td>
                <td className={className}>{booking.bookingtype}</td>
                <td className={className}>
                  {booking.bookingtype.toLowerCase() === 'walker'
                    ? `${formatDate(booking.startdate)} -> ${booking.starttime}-${booking.endtime}`
                    : `${formatDate(booking.startdate)} -> ${formatDate(booking.enddate)}`}
                </td>
                <td className={`capitalize ${className}`}>{booking.status}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Layout>
  );
};

export default MyBookingsPage;
