import React from 'react';

const RadioInput = ({ label, checked, onChange, id }) => {
  return (
    <div className="flex items-center md:space-x-1 space-x-2">
      <input
        id={id}
        type="radio"
        checked={checked}
        onChange={onChange}
        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
      />
      <label htmlFor={id} className="w-full py-3 ms-2 md:text-sm font-medium text-gray-900">
        {label}
      </label>
    </div>
  );
};

export default RadioInput;
