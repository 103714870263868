import React from 'react';
import { Switch, BrowserRouter as Router, Redirect } from 'react-router-dom';
import GuestRoute from '@router/GuestRoute';
import RegisterPage from '@pages/RegisterPage';
import LoginPage from '@pages/LoginPage';
import LandingPage from '@pages/LandingPage';
import AuthRoute from '@router/AuthRoute';
import DashboardPage from '@pages/DashboardPage';
import KeeperPage from '@pages/KeeperPage';
import ChatPage from '@pages/ChatPage';
import ChangePasswordPage from '@pages/ChangePasswordPage';
import CheckoutPage from '@pages/CheckoutPage';
import ForgotPasswordPage from '@pages/ForgotPasswordPage';
import ResetPasswordPage from '@pages/ResetPasswordPage';
import MyBookingsPage from '@pages/MyBookingsPage';

const Routes = () => {
  return (
    <Router>
      <Switch>
        <GuestRoute exact path="/register" component={RegisterPage} />
        <GuestRoute exact path="/login" component={LoginPage} />
        <AuthRoute exact path="/dashboard" component={DashboardPage} />
        <AuthRoute exact path="/keeper/:keeperId" component={KeeperPage} />
        <AuthRoute exact path="/chat" component={ChatPage} />
        <AuthRoute exact path="/change-password" component={ChangePasswordPage} />
        <AuthRoute exact path="/checkout" component={CheckoutPage} />
        <GuestRoute exact path="/forgot-password" component={ForgotPasswordPage} />
        <GuestRoute exact path="/reset-password" component={ResetPasswordPage} />
        <AuthRoute exact path="/my-bookings" component={MyBookingsPage} />
        <GuestRoute exact path="/" component={LandingPage} />
        <Redirect from="*" to="/" />
      </Switch>
    </Router>
  );
};

export default Routes;
