import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

export const AuthRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector((state) =>
    _.get(state, 'authenticationReducer.accessToken', false)
  );

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          return <Component {...props} />;
        }
        return (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        );
      }}
    />
  );
};

export default AuthRoute;
