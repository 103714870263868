import React from 'react';
import { Link } from 'react-router-dom';

const NavItem = ({ icon: Icon, title, to, link = false, onClick }) => {
  const className =
    'flex items-center p-1.5 rounded-lg cursor-pointer hover:bg-primary-200 md:space-x-0.5';

  if (link) {
    return (
      <Link to={to} className={className}>
        <Icon size={18} />
        <span className="hidden md:block">{title}</span>
      </Link>
    );
  }

  return (
    <div onClick={onClick} className={className}>
      <Icon size={18} />
      <span className="hidden md:block">{title}</span>
    </div>
  );
};

export default NavItem;
