import React, { useState } from 'react';
import Input from '@core/inputs/Input';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import useFormErrors from '@hooks/useFormErrors';
import API from '@utils/plugins/API';
import { showError, showSuccess } from '@utils/helpers';
import RegisterValidator from '@utils/validators/registerValidator';
import Select from '@core/Select';
import { citiesInAlbania } from '@constants/index';
import EmailVerificationModal from '@components/Signup/EmailVerificationModal';
import SubmitButton from '@core/buttons/atoms/SubmitButton';
import AuthLayout from '@hoc/layouts/AuthLayout';

const RegisterPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const { clearErrors, validateErrors, getError } = useFormErrors();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [viewEnterConfirmationCodeModal, setViewEnterConfirmationModal] = useState(false);

  const handleSubmit = async () => {
    const errors = validateErrors(
      { email, fullName, password, address, phoneNumber },
      RegisterValidator
    );
    if (errors) {
      return;
    }

    setIsLoading(true);
    API.post('/register', { email, password, fullName, address, phoneNumber })
      .then((res) => {
        setViewEnterConfirmationModal(true);
        showSuccess(res.data.message);
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <AuthLayout>
      <h1 className="text-2xl text-center font-semibold">Register</h1>
      <div className="grid grid-cols-2 gap-3 mt-8">
        <Input
          label="Full Name"
          placeholder="John Doe"
          value={fullName}
          onChange={setFullName}
          error={getError('fullName')}
          onFocus={clearErrors}
          required
        />
        <Input
          label="Email"
          placeholder="test@example.com"
          value={email}
          onChange={setEmail}
          error={getError('email')}
          onFocus={clearErrors}
          required
        />
        <Input
          label="Phone Number"
          placeholder="06x xxx xxxx"
          value={phoneNumber}
          onChange={setPhoneNumber}
          error={getError('phoneNumber')}
          onFocus={clearErrors}
          required
        />
        <Select
          label="Address"
          placeholder="Please select a city"
          options={citiesInAlbania.map((city) => ({ label: city, value: city }))}
          onChange={setAddress}
          value={address}
          error={getError('address')}
          onFocus={clearErrors}
          required
        />
      </div>
      <Input
        label="Password"
        placeholder="Min. 8 characters, 1 Number, 1 Uppercase Letter"
        value={password}
        type="password"
        onChange={setPassword}
        error={getError('password')}
        onFocus={clearErrors}
        required
      />
      <div className="mt-5">
        <SubmitButton isLoading={isLoading} sm label="Register" fullWidth onClick={handleSubmit} />
      </div>

      <EmailVerificationModal
        isOpen={viewEnterConfirmationCodeModal}
        email={email}
        setOpenModal={setViewEnterConfirmationModal}
      />
    </AuthLayout>
  );
};

export default RegisterPage;
