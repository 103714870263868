import React from 'react';

const Select = ({
  children,
  error,
  placeholder = 'Please select an option',
  onChange,
  value,
  label,
  className,
  required = false,
  fullWidth = false,
  options = [],
  ...rest
}) => {
  return (
    <div>
      {label && (
        <div className="pl-1 pb-0.5 text-sm">
          {label} <span className="text-red-500">{required && '*'}</span>
        </div>
      )}
      <select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={`relative block w-full px-3 py-2 border border-gray-300 rounded text-gray-900 bg-white focus:outline-none focus:z-10 sm:text-sm ${className}`}
        {...rest}>
        <option disabled selected hidden value="">
          {placeholder}
        </option>
        {options.map((option, index) => (
          <option value={option.value} key={index} >{option.label}</option>
        ))}
      </select>
      {error && <div className={`text-xs text-red-400 pt-2 ${!error && 'pb-4'}`}>{error}</div>}
    </div>
  );
};

export default Select;
