import React, { useState } from 'react';
import AuthLayout from '@hoc/layouts/AuthLayout';
import API from '@utils/plugins/API';
import { authenticate } from '@redux/authentication/Action';
import { showError } from '@utils/helpers';
import { Link, useHistory } from 'react-router-dom';
import useFormErrors from '@hooks/useFormErrors';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import Input from '@core/inputs/Input';
import SubmitButton from '@core/buttons/atoms/SubmitButton';
import loginValidator from '@utils/validators/loginValidator';

const LoginPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { validateErrors, clearErrors, getError } = useFormErrors();

  const onSubmit = (e) => {
    e.preventDefault();
    const errors = validateErrors({ email, password }, loginValidator);
    if (errors) {
      return;
    }
    setIsLoading(true);
    API.post('/login', { email, password })
      .then((response) => {
        const authentication = _.get(response, 'data.tokenData', '');
        const user = _.get(response, 'data.user', '');
        dispatch(authenticate(authentication, user));
        history.push('/dashboard');
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <AuthLayout maxWidth="max-w-md">
      <div>
        <h2 className="mt-5 text-center text-3xl font-semibold text-gray-900">
          Sign in to your account
        </h2>
      </div>
      <form className="mt-8 space-y-3" onSubmit={onSubmit}>
        <div className="rounded-md shadow-sm space-y-3">
          <Input
            value={email}
            error={getError('email')}
            placeholder={'Email address'}
            onChange={setEmail}
            onFocus={clearErrors}
            required
          />
          <Input
            error={getError('password')}
            type={'password'}
            value={password}
            placeholder={'Password'}
            onChange={setPassword}
            required
            onFocus={clearErrors}
          />
        </div>
        <div className="text-center">
          <Link to={`/forgot-password?email=${email}`}>Forgot Password?</Link>
        </div>
        <SubmitButton isLoading={isLoading} type="submit" label="Sign in" fullWidth />
      </form>
    </AuthLayout>
  );
};

export default LoginPage;
