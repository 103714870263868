const rateKeeperVaidator = {
  rating: {
    presence: {
      allowEmpty: false,
      message: "Rating can't be blank"
    }
  }
};

export default rateKeeperVaidator;
