import React from 'react';

const ReceiverMessage = ({ user, text, isPreviousMessageSentByUser }) => {
  return (
    <div className={!isPreviousMessageSentByUser ? 'mt-2' : 'mt-1'}>
      {!isPreviousMessageSentByUser && <p className="text-left text-sm md:text-lg">{user}</p>}
      <div className="bg-gray-400 text-xs md:text-base w-40 md:w-60 lg:w-80 p-1.5 md:p-2.5 rounded-xl">
        <p>{text}</p>
      </div>
    </div>
  );
};

export default ReceiverMessage;
