import React from 'react';

const MyMessage = ({ content, isPreviousMessageSentByUser = false }) => {
  return (
    <div className={!isPreviousMessageSentByUser ? 'mt-2' : 'mt-1'}>
      {!isPreviousMessageSentByUser && <p className="text-right text-sm md:text-lg">You</p>}
      <div className="bg-primary text-white text-xs md:text-base w-40 md:w-60 lg:w-80 ml-auto rounded-xl p-1.5 md:p-2.5">
        <p>{content}</p>
      </div>
    </div>
  );
};

export default MyMessage;
