import { ADD_AUTH, REMOVE_AUTH, UPDATE_ACCESS_TOKEN } from '@constants/redux';
import { addMe, removeMe } from '@redux/me/Action';
import { clearFilters } from '@redux/keeper/Action';

export const authenticate =
  (authentication, user = null) =>
  (dispatch) => {
    dispatch(addAuth(authentication));
    if (user) {
      dispatch(addMe(user));
    }
  };

export const logout = () => (dispatch) => {
  dispatch(removeAuth());
  dispatch(removeMe());
  dispatch(clearFilters());
};

export const addAuth = (payload) => {
  return {
    type: ADD_AUTH,
    payload
  };
};

export const updateAccessToken = (payload) => {
  return { type: UPDATE_ACCESS_TOKEN, payload };
};

export const removeAuth = () => {
  return {
    type: REMOVE_AUTH
  };
};
