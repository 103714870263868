import React from 'react';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import { useHistory } from 'react-router-dom';

const Result = ({ result }) => {
  const history = useHistory();
  const navigate = () => history.push(`/keeper/${result.keeperid}`);
  return (
    <div className="w-full">
      <div className="rounded border p-3">
        <div className="flex flex-col space-y-5 md:space-y-0 items-start  md:flex-row md:space-x-5">
          <img
            alt="keeper image"
            src={result.image}
            className="w-full aspect-[1/1] md:w-30 md:h-30 lg:w-60 lg:h-60 xl:w-100 xl:h-100 rounded-lg object-cover"
          />
          <div className="w-full flex-1">
            <div className="text-xl font-medium truncate whitespace-pre-line">
              {result.fullname}
            </div>
            <div className="flex items-center my-1.5">
              {result.isKeeper && (
                <div className="px-2 py-1 bg-primary mr-3 rounded text-white text-sm">Keeper</div>
              )}
              {result.isWalker && (
                <div className="px-2 py-1 bg-primary rounded text-white text-sm">Walker</div>
              )}
            </div>
            <p className="text-gray-700 text-base">{result.address}</p>
            <p className="text-gray-700 text-base">Rating: {parseInt(result.average_score)}/10</p>
            <p className="text-gray-700 text-base truncate whitespace-pre-line">
              {result.description}
            </p>
            <DefaultButton onClick={navigate} sm label="Book now" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Result;
