const resetPasswordValidator = {
  confirmationCode: {
    presence: {
      allowEmpty: false,
      message: "Confirmation Code can't be blank"
    }
  },
  newPassword: {
    presence: {
      allowEmpty: false,
      message: "Password can't be blank"
    }
  }
};

export default resetPasswordValidator;
