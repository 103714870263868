import React from 'react';
import Lottie from '@core/Lottie';

const BookingUnavailableAnimation = ({ message = "This keeper isn't available" }) => {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="w-50 md:w-60 lg:w-80">
        <Lottie
          options={{
            autoplay: true,
            loop: false,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            },
            animationData: require('@assets/animations/booking_unavailable.json')
          }}
        />
        <div className="text-center font-medium text-lg mt-2">{message}</div>
      </div>
    </div>
  );
};

export default BookingUnavailableAnimation;
