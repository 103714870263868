import React from 'react';
import { IoIosChatbubbles } from 'react-icons/io';
import NavItem from '@core/navbar/NavItem';
import { FaUser } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { logout } from '@redux/authentication/Action';
import { Menu } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { FaCalendarAlt } from "react-icons/fa";


const Navbar = () => {
  const dispatch = useDispatch();
  return (
    <div className="flex items-center px-8 md:px-10 lg:px-18 py-5 justify-between bg-primary w-full">
      <div>Putrat</div>
      <div className="flex items-center space-x-2 md:space-x-3">
        <NavItem title="My Bookings" link icon={FaCalendarAlt} to="/my-bookings" />
        <NavItem title="Chat" link icon={IoIosChatbubbles} to="/chat" />
        <Menu as="div" className="relative">
          <Menu.Button>
            <NavItem title="User" icon={FaUser} />
          </Menu.Button>
          <Menu.Items className="absolute right-0 bg-white rounded-md shadow-lg border whitespace-nowrap">
            <Menu.Item>
              <Link
                to="/change-password"
                className="hover:bg-gray-100 block px-4 py-2 text-sm text-gray-700 cursor-pointer">
                Change Password
              </Link>
            </Menu.Item>
            <Menu.Item>
              <div
                className="hover:bg-gray-100 block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                onClick={() => dispatch(logout())}>
                Log Out
              </div>
            </Menu.Item>
          </Menu.Items>
        </Menu>
      </div>
    </div>
  );
};

export default Navbar;
