import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import API from '@utils/plugins/API';
import LoadingKeeper from '@components/Keeper/LoadingKeeper';
import { Carousel } from 'react-responsive-carousel';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import RateKeeperModal from '@components/Keeper/RateKeeperModal';
import KeeperLoadingFailed from '@components/Keeper/KeeperLoadingFailed';
import { Rating } from '@mui/material';
import { updateKeeperId } from '@redux/keeper/Action';
import { useHistory } from 'react-router-dom';

const KeeperDetails = () => {
  const { keeperId } = useParams();
  const [keeper, setKeeper] = useState({});
  const [viewRateKeeperModal, setViewRateKeeperModal] = useState(false);
  const [reloaded, setReloaded] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const userId = useSelector((state) => _.get(state, 'meReducer.userid', undefined));
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    API.get(`/keeper`, { params: { keeperid: keeperId } })
      .then((res) => {
        setKeeper(res.data);
        setIsSuccess(true);
      })
      .catch(() => {
        setIsSuccess(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [keeperId, reloaded]);

  const reload = () => setReloaded((prevState) => prevState + 1);
  const canReview = !keeper?.reviews?.some((review) => review.reviewerId === parseInt(userId));

  const handleClick = () => {
    dispatch(updateKeeperId(keeperId));
    history.push('/checkout');
  };

  if (isLoading) {
    return <LoadingKeeper />;
  }

  if (isSuccess) {
    return (
      <div>
        <div className="grid grid-cols-2 gap-10">
          <div>
            <Carousel>
              {keeper?.images?.map((image) => (
                <img src={image} alt="image" className="aspect-[4.5/3] object-cover" />
              ))}
            </Carousel>
          </div>
          <div className="space-y-1.5">
            <h1 className="text-2xl font-bold">{keeper.fullname}</h1>
            <div className="text-lg">{keeper.address}</div>
            <div className="text-base">{keeper.description}</div>
            <div className="flex items-center space-x-8">
              <div>Rating: {keeper.avgscore?.toFixed()}/10</div>
              {canReview && (
                <DefaultButton
                  xs
                  label="Rate this keeper"
                  onClick={() => setViewRateKeeperModal(true)}
                />
              )}
            </div>
            <DefaultButton sm label="Book this keeper" onClick={handleClick} />
          </div>
          <RateKeeperModal
            setOpenModal={setViewRateKeeperModal}
            keeperId={keeperId}
            reload={reload}
            isOpen={viewRateKeeperModal}
          />
        </div>
        <div>
          <div className="text-2xl font-medium">Reviews</div>

          {keeper?.reviews.length ? (
            <div className="space-y-3 mt-2">
              {keeper.reviews.map((review) => (
                <div>
                  <div className="flex items-center space-x-3">
                    <div className="text-lg">{review.reviewerName}</div>
                    <Rating disabled value={review.stars / 2} precision={0.5} />
                  </div>
                  <div>{review.reviewText}</div>
                </div>
              ))}
            </div>
          ) : (
            'No reviews'
          )}
        </div>
      </div>
    );
  }

  return <KeeperLoadingFailed />;
};

export default KeeperDetails;
