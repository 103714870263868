import React from 'react';
import { CgSpinner } from 'react-icons/cg';
import DefaultButton from '@core/buttons/electrons/DefaultButton';

const SubmitButton = ({ isLoading, label, onClick, ...rest }) => {
  return (
    <DefaultButton
      onClick={onClick}
      disabled={isLoading}
      fullWidth
      sm
      label={
        <div className="flex items-center">
          {isLoading && <CgSpinner className="mr-1 animate-spin" size={15} />}
          {label}
        </div>
      }
      {...rest}
    />
  );
};

export default SubmitButton;
