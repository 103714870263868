import React from 'react';
import Lottie from '@core/Lottie';

const BookingAvailableAnimation = () => {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="w-full">
        <Lottie
          options={{
            autoplay: true,
            loop: true,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            },
            animationData: require('@assets/animations/booking_available.json')
          }}
        />
        <div className="text-center font-medium text-lg mt-2">This keeper is available</div>
      </div>
    </div>
  );
};

export default BookingAvailableAnimation;
