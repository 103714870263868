import React, { useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { updateFilters } from '@redux/keeper/Action';
import Layout from '@hoc/layouts/Layout';
import useUpdateEffect from 'use-update-effect';
import useFormErrors from '@hooks/useFormErrors';
import searchValidator from '@utils/validators/searchValidator';
import API from '@utils/plugins/API';
import Input from '@core/inputs/Input';
import Select from '@core/Select';
import SubmitButton from '@core/buttons/atoms/SubmitButton';
import Pagination from '@hoc/partials/Pagination';
import Sidebar from '@components/Dashboard/Sidebar';
import Result from '@components/Dashboard/Result';
import NoResults from '@components/Dashboard/NoResults';
import SearchTypeInput from '@components/Dashboard/SearchTypeInput';
import { animalTypes, citiesInAlbania } from '@constants/index';

const DashboardPage = () => {
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const [searchType, setSearchType] = useState('');
  const [address, setAddress] = useState('');
  const [numberOfPets, setNumberOfPets] = useState('');
  const [typeOfPets, setTypeOfPets] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [date, setDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const dispatch = useDispatch();
  const { validateErrors, getError, clearErrors } = useFormErrors();

  const getData = () => {
    const payload = {
      address,
      numberOfPets,
      searchType,
      typeOfPets,
      ...(searchType === 'walker' ? { date, startTime, endTime } : { startDate, endDate })
    };
    const errors = validateErrors(payload, searchValidator);
    if (errors) return;
    setIsLoading(true);
    API.get('/search', { params: { ...payload, page, pageSize, sortDirection, sortColumn } })
      .then((res) => {
        const isWalker = searchType === 'walker';
        dispatch(
          updateFilters({
            numberOfPets,
            typeOfPets,
            isWalker,
            ...(isWalker ? { date, startTime, endTime } : { startDate, endDate })
          })
        );
        const { records, totalPages } = res.data;
        setResults(records);
        setTotalPages(totalPages);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useUpdateEffect(() => {
    getData();
  }, [page, pageSize, sortDirection, sortColumn]);

  const handleSearch = () => {
    getData();
  };

  return (
    <Layout>
      <div className="w-full md:w-[45vw] mx-auto">
        <div className="text-lg font-semibold mb-1">Search for a keeper</div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-x-5 gap-y-3 md:gap-y-2">
          <Select
            label="Address"
            placeholder="Please select a city"
            options={citiesInAlbania.map((city) => ({ label: city, value: city }))}
            onChange={setAddress}
            value={address}
            error={getError('address')}
            onFocus={clearErrors}
          />
          <Select
            options={animalTypes}
            placeholder="Type Of Pets"
            label="Type of pets"
            value={typeOfPets}
            onChange={setTypeOfPets}
            error={getError('typeOfPets')}
            onFocus={clearErrors}
          />
          <Input
            label="Number of pets"
            type="number"
            placeholder="Number Of Pets"
            value={numberOfPets}
            min={1}
            onChange={setNumberOfPets}
          />
          {searchType === 'walker' ? (
            <>
              <Input
                type="date"
                label="Date"
                placeholder="Date"
                value={date}
                min={moment().format('YYYY-MM-DD')}
                onChange={setDate}
              />
              <Input
                label="Start Time"
                type="time"
                placeholder="Start Time"
                onChange={setStartTime}
                value={startTime}
                min="08:00"
                step={1800}
                max="20:00"
              />
              <Input
                label="End Time"
                type="time"
                placeholder="End Time"
                onChange={setEndTime}
                step={1800}
                value={endTime}
                min={moment(startTime, 'HH:mm').add(30, 'minutes').format('HH:mm')}
                max="20:00"
              />
            </>
          ) : (
            <>
              <Input
                type="date"
                label="Start Date"
                placeholder="Start Date"
                value={startDate}
                min={moment().format('YYYY-MM-DD')}
                onChange={setStartDate}
              />
              <Input
                type="date"
                label="End Date"
                placeholder="End Date"
                value={endDate}
                min={moment(startDate).add(1, 'day').format('YYYY-MM-DD')}
                onChange={setEndDate}
              />
            </>
          )}
          <div className="self-end">
            <SubmitButton isLoading={isLoading} fullWidth label="Search" onClick={handleSearch} />
          </div>
          <SearchTypeInput
            onChange={setSearchType}
            value={searchType}
            error={getError('searchType')}
          />
        </div>
      </div>
      {results.length > 0 ? (
        <div className="flex flex-col items-center md:items-start w-full md:flex-row mt-10 space-y-5 md:space-y-0 md:space-x-10">
          <Sidebar
            setSortColumn={setSortColumn}
            setSortDirection={setSortDirection}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
          />
          <div className="md:flex-1 w-full space-y-5">
            {results.map((result, index) => (
              <Result result={result} key={index} />
            ))}
            <Pagination
              pageSize={pageSize}
              setPageSize={setPageSize}
              page={page}
              pageCount={totalPages}
              onPageChange={(v) => setPage(v)}
            />
          </div>
        </div>
      ) : (
        <NoResults />
      )}
    </Layout>
  );
};

export default DashboardPage;
