import React from 'react';
import Lottie from '@core/Lottie';

const NoResults = () => {
  return (
    <div className="w-80 mx-auto">
      <Lottie
        animationData={require('@assets/animations/search.json')}
        options={{ autoPlay: true }}
      />
      <p className="text-center font-medium text-lg">Start searching for a keeper</p>
    </div>
  );
};

export default NoResults;
