import { combineReducers } from 'redux';
import spinnerReducer from './spinner/Reducer';
import authenticationReducer from '@redux/authentication/Reducer';
import meReducer from '@redux/me/Reducer';
import keeperReducer from '@redux/keeper/reducer';

const Reducers = combineReducers({
  spinnerReducer,
  authenticationReducer,
  meReducer,
  keeperReducer
});

export default Reducers;
