import React from 'react';
import Lottie from 'react-lottie';

const LoadingKeeper = () => {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="w-20 md:w-20 lg:w-30">
        <Lottie
          options={{
            autoplay: true,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            },
            animationData: require('@assets/animations/loading.json')
          }}
        />
      </div>
    </div>
  );
};

export default LoadingKeeper;
