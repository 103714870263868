import React, { useState } from 'react';
import BlankModal from '@core/modals/BlankModal';
import { Rating } from '@mui/material';
import Input from '@core/inputs/Input';
import SubmitButton from '@core/buttons/atoms/SubmitButton';
import useFormErrors from '@hooks/useFormErrors';
import API from '@utils/plugins/API';
import { showError, showSuccess } from '@utils/helpers';
import rateKeeperVaidator from '@utils/validators/rateKeeperValidator';

const RateKeeperModal = ({ keeperId, isOpen, setOpenModal, reload }) => {
  const [rating, setRating] = useState();
  const [description, setDescription] = useState('');

  const { validateErrors, getError, clearErrors } = useFormErrors();

  const labels = {
    0.5: 'Useless',
    1: 'Useless+',
    1.5: 'Poor',
    2: 'Poor+',
    2.5: 'Ok',
    3: 'Ok+',
    3.5: 'Good',
    4: 'Good+',
    4.5: 'Excellent',
    5: 'Excellent+'
  };

  const onClose = () => {
    setDescription('');
    setRating(undefined);
  };

  const handleSubmit = () => {
    const errors = validateErrors({ description, rating }, rateKeeperVaidator);
    if (errors) {
      return;
    }
    API.post('/newReview', {
      reviewtext: description,
      rating: rating * 2,
      keeperid: keeperId
    })
      .then((res) => {
        showSuccess(res.data.message);
        setOpenModal(false);
        reload();
      })
      .catch((err) => {
        showError(err.response.data.message);
      });
  };

  return (
    <BlankModal
      onClose={onClose}
      setOpenModal={setOpenModal}
      title="Rate this keeper"
      isOpen={isOpen}
      maxWidth="max-w-xl">
      <div className="space-y-3">
        <div>
          <div className="flex items-center w-40 mx-auto space-x-2">
            <Rating
              classes={{ root: 'mx-auto static' }}
              precision={0.5}
              onFocus={clearErrors}
              value={rating}
              onChange={(e) => setRating(e.target.value)}
            />
            {rating && <div>{labels[rating]}</div>}
          </div>
          {getError('rating') && (
            <div className={`text-xs text-red-400 pt-2 text-center`}>{getError('rating')}</div>
          )}
        </div>
        <Input placeholder="Description" value={description} onChange={setDescription} />
        <SubmitButton fullWidth label="Submit" onClick={handleSubmit} />
      </div>
    </BlankModal>
  );
};

export default RateKeeperModal;
