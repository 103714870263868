import React from 'react';
import Layout from '@hoc/layouts/Layout';
import KeeperDetails from '@components/Keeper/KeeperDetails';

const KeeperPage = () => {
  return (
    <Layout>
      <KeeperDetails />
    </Layout>
  );
};

export default KeeperPage;
