import { CLEAR_FILTERS, UPDATE_FILTERS, UPDATE_KEEPER } from '@constants/redux';

const keeperReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_FILTERS:
      return { ...state, ...action.payload };
    case CLEAR_FILTERS:
      return {};
    case UPDATE_KEEPER:
      return { ...state, keeperId: action.payload };
    default:
      return state;
  }
};

export default keeperReducer;
