const searchValidator = {
  address: {
    presence: {
      allowEmpty: false,
      message: "Address can't be blank"
    }
  },
  typeOfPets: {
    presence: {
      allowEmpty: false,
      message: "Type Of Pets can't be blank"
    }
  },
  searchType: {
    presence: {
      allowEmpty: false,
      message: "Booking Type can't be blank"
    }
  }
};

export default searchValidator;
