export const API_URL = process.env.REACT_APP_API_URL;

export const citiesInAlbania = [
  'Tirana',
  'Durrës',
  'Vlorë',
  'Shkodër',
  'Fier',
  'Korçë',
  'Berat',
  'Lushnjë',
  'Elbasan',
  'Kavajë',
  'Pogradec',
  'Laç',
  'Kukës',
  'Lezhë',
  'Gjirokastër',
  'Sarandë',
  'Patos',
  'Peshkopi',
  'Burrel',
  'Cërrik'
];

export const animalTypes = [
  { label: 'Dogs', value: 'Dogs' },
  { label: 'Cats', value: 'Cats' },
  { label: 'Fish', value: 'Fish' },
  { label: 'Birds', value: 'Birds' },
  { label: 'Turtles', value: 'Turtles' }
];
