// Pagination.js
import React from 'react';
import ReactPaginate from 'react-paginate';
import 'tailwindcss/tailwind.css';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Select from '@core/Select';

const Pagination = ({ page = 1, pageCount, onPageChange, pageSize, setPageSize, ...props }) => {
  const handlePageChange = ({ selected }) => {
    onPageChange(selected + 1);
  };

  return (
    <div className="flex items-center space-x-4 justify-center my-4" {...props}>
      <ReactPaginate
        pageCount={pageCount}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        previousLabel={<FaChevronLeft />}
        nextLabel={<FaChevronRight />}
        containerClassName="flex text-center items-center justify-end my-3"
        breakClassName="text-primary-600"
        pageClassName="text-gray-400 rounded-full justify-center flex flex-col h-7 w-7 mx-1.5 text-sm leading-5 font-medium"
        activeClassName="bg-primary rounded-full text-white justify-center flex flex-col h-7 w-7 text-sm leading-5 font-medium"
        disabledClassName="hidden"
        onPageChange={handlePageChange}
        forcePage={page - 1 > pageCount ? 0 : page - 1}
      />
      <Select
        placeholder="Page Size"
        value={pageSize}
        onChange={setPageSize}
        options={[
          { label: 10, value: 10 },
          { label: 15, value: 15 },
          { label: 30, value: 30 }
        ]}
      />
    </div>
  );
};

export default Pagination;
